import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { CartDTO } from "@/dto/cart/CartDTO";
import { CartProductFrontEnd } from "@/dto/cart/CartProductDTO";
import { CartDiscountExtend } from "@/dto/cart/CartDiscountDTO";
import { ModalityExtend } from "@/dto/ModalityDTO";
import { PaymentTypeDTO } from "@/dto/PaymentTypeDTO";
import { type BookingProcessInterface } from "@/class/cart/controller/CartStatus";

export const useCartStore = defineStore("cart", () => {

  // State
  const cart = ref(new CartDTO());
  const isCartVisible = ref(false);
  const isPlanningToDisplay = ref(false);
  const products = ref<CartProductFrontEnd[]>([]);
  const discounts = ref<CartDiscountExtend[]>([]);
  const modality = ref(new ModalityExtend());
  const paymentType = ref(new PaymentTypeDTO());
  const statusRouterTodo = ref<BookingProcessInterface[]>([]);
  const { generate } = useUUID();

  // Getters
  const cartSize = computed(() => products.value.length);
  const additionalProducts = computed(() =>
    products.value.filter((product) =>
      ["license", "membership", "inscription", "clothing"].includes(
        product.productType
      )
    )
  );

  const discountsList = computed(() => discounts.value);

  // Actions
  function addToCart(newProduct: CartProductFrontEnd) {
    newProduct.storeUuid = generate();
    products.value.push(newProduct);
  }

  function removeProductFromCart(id: string | number) {
    const index = products.value.findIndex(
      (product) => product.id === id
    );

    if (index !== -1) products.value.splice(index, 1);
  }

  function addDiscount(newDiscount: CartDiscountExtend) {
    newDiscount.storeUuid = generate();
    discounts.value.push(newDiscount);
  }

  function removeDiscount(storeUuid: string) {
    const index = discounts.value.findIndex(
      (discount) => discount.storeUuid === storeUuid
    );
    if (index !== -1) discounts.value.splice(index, 1);
  }

  function displayCart() {
    isCartVisible.value = true;
  }

  function hideCart() {
    isCartVisible.value = false;
  }

  function toggleCartVisibility() {
    isCartVisible.value = !isCartVisible.value;
  }

  function setCart(newCart: CartDTO) {
    cart.value = { ...newCart };
  }

  function setProducts(newProducts: CartProductFrontEnd[]) {
    products.value = newProducts.map((product) => ({
      ...product,
      storeUuid: generate()
    }));
  }

  function setDiscounts(newDiscounts: CartDiscountExtend[]) {
    discounts.value = newDiscounts.map((discount) => ({
      ...discount,
      storeUuid: generate()
    }));
  }

  function setModality(newModality: ModalityExtend) {
    modality.value = newModality;
    paymentType.value = new PaymentTypeDTO();
  }

  function setPaymentType(newPaymentType: PaymentTypeDTO) {
    paymentType.value = newPaymentType;
  }

  function setStatusRouterTodo(newStatusRouterTodo: BookingProcessInterface[]) {
    statusRouterTodo.value = [...newStatusRouterTodo];
  }

  function modifyProduct(modifiedProduct: CartProductFrontEnd) {
    const indexToModify = products.value.findIndex((product: CartProductFrontEnd) => {
      return product.storeUuid === modifiedProduct.storeUuid;
    });

    if (indexToModify === -1) {
      throw new Error(
        'Tried to modify product but storeUuid of given product does not match any product in store : ' + modifiedProduct.storeUuid
      );
    }

    // Directly update the product at the found index
    products.value[indexToModify] = modifiedProduct;
  }

  // Computed: Derived properties for specific product types
  const licenses = computed(() =>
    products.value.filter((product) => product.productType === "license")
  );

  const memberships = computed(() =>
    products.value.filter((product) => product.productType === "membership")
  );

  const inscriptions = computed(() =>
    products.value.filter((product) => product.productType === "inscription")
  );

  const clothings = computed(() =>
    products.value.filter((product) => product.productType === "clothing")
  );

  const epasses = computed(() =>
    products.value.filter((product) => product.productType === "epass")
  );

  const formats = computed(() =>
    products.value.filter((product) => product.productType === "format")
  );

  return {
    cart,
    isCartVisible,
    isPlanningToDisplay,
    products,
    licenses,
    memberships,
    inscriptions,
    clothings,
    epasses,
    formats,
    discounts,
    modality,
    paymentType,
    statusRouterTodo,
    cartSize,
    additionalProducts,
    discountsList,
    addToCart,
    removeProductFromCart,
    addDiscount,
    removeDiscount,
    displayCart,
    hideCart,
    toggleCartVisibility,
    setCart,
    setProducts,
    setDiscounts,
    setModality,
    setPaymentType,
    setStatusRouterTodo,
    modifyProduct
  };
});
