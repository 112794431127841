import { CartProductFrontEnd } from "./CartProductDTO";
import { CartDiscountExtend } from "./CartDiscountDTO";
import { OrderInvoiceDTO } from "../order/OrderInvoiceDTO";

export enum CartStatus {
  init = "0-init",
  participant = "1-participant",
  additional = "2-additional-products",
  discount = "3-discount",
  modality = "4-modality",
  paymentType = "5-payment-type",
  profilStudent = "6-questions",
  buyer = "7-profil-buyer",
  CGV = "8-cgv",
  payment = "9-payment",
  validation = "10-validation",
  generated = "11-generated",
}

export type CartObject = CartProductFrontEnd | CartDiscountExtend | OrderInvoiceDTO;

export interface BookingProcessInterface {
  step: number;
  rooter: string;
  title: string;
  cartStatus: CartStatus;
}

const BookingAllSteps: BookingProcessInterface[] = [
  {
    title: "Participant",
    rooter: "booking-participant",
    step: 1,
    cartStatus: CartStatus.participant,
  },
  {
    title: "Additional",
    rooter: "booking-additional",
    step: 2,
    cartStatus: CartStatus.additional,
  },
  {
    title: "Réductions",
    rooter: "booking-discounts",
    step: 3,
    cartStatus: CartStatus.discount,
  },
  {
    title: "Modalités",
    rooter: "booking-modality",
    step: 4,
    cartStatus: CartStatus.modality,
  },
  {
    title: "Moyen de paiement",
    rooter: "booking-payment-type",
    step: 5,
    cartStatus: CartStatus.paymentType,
  },
  {
    title: "Questions",
    rooter: "booking-questions",
    step: 6,
    cartStatus: CartStatus.profilStudent,
  },
  {
    title: "Acheteur",
    rooter: "booking-buyer",
    step: 7,
    cartStatus: CartStatus.buyer,
  },
  {
    title: "CGV",
    rooter: "booking-cgv",
    step: 8,
    cartStatus: CartStatus.CGV,
  },
  {
    title: "Paiement",
    rooter: "booking-payment",
    step: 9,
    cartStatus: CartStatus.payment,
  },
  {
    title: "Validation",
    rooter: "booking-validation",
    step: 10,
    cartStatus: CartStatus.validation,
  },
];

export class CartDTO {
  public id = 0;
  public publicId = "";
  public userId = 0;
  public buyerMasterId = 0;
  public status = CartStatus.participant;
  public orgaId = 0;
  public modalityId = 0;
  public buyerOrganizerIdWhenOrga = 0;
  public saveOrderId = 0;
  public date = "";
  public dateModified = "";
  public paymentTypeId = 0;
  public saveTrsPayId = 0;
  public signaturePath = "";
  public cgvPath = "";
  public isCgvValidated = false;
  public cgvPdfPath = "";
  public cgvRegulationPdfPath = "";
  public isInfoGlobalValidated = false;
  public prorataAmountOnlive = 0;
  public prorataAmountOnline = 0;
  public archiveId = 0;

  constructor(init?: Partial<CartDTO>) {
    if (init === undefined) return;

    Object.assign(this, init);
  }
}
