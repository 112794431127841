import { CartPost } from "../model/CartPost";
import { ModalityExtend } from "@/dto/ModalityDTO";
import { PaymentTypeDTO } from "@/dto/PaymentTypeDTO";
import { KeyPaymentType } from "@/class/KeyPaymentType";
import { OrganizerDTO } from "@/dto/organizer/OrganizerDTO";

export class CartControllerUpdate {
  private modalityId: number = 0;
  private isCgvValidated: boolean = false;
  private isInfoGlobalValidated: boolean = false;
  private paymentTypeId: number = 0;
  private prorataAmountOnline: number = 0;
  private prorataAmountOnlive: number = 0;
  private buyerOrganizerIdWhenOrga: number = 0;
  private cartStore = useCartStore();

  /**
   * Updates the cart with the latest changes.
   */
  public async update(): Promise<void> {
    try {
      await this.updateCart();
    } catch (error) {
      console.error("Error updating the cart:", error);
    }
  }

  /**
   * Handles the logic for updating the cart.
   */
  private async updateCart(): Promise<void> {
    const cart = this.cartStore.cart;

    cart.modalityId = this.modalityId || cart.modalityId;
    cart.paymentTypeId = this.paymentTypeId || cart.paymentTypeId;
    cart.buyerOrganizerIdWhenOrga = this.buyerOrganizerIdWhenOrga || cart.buyerOrganizerIdWhenOrga;
    cart.prorataAmountOnline = this.prorataAmountOnline || cart.prorataAmountOnline;
    cart.prorataAmountOnlive = this.prorataAmountOnlive || cart.prorataAmountOnlive;
    cart.isCgvValidated = this.isCgvValidated || cart.isCgvValidated;
    cart.isInfoGlobalValidated = this.isInfoGlobalValidated || cart.isInfoGlobalValidated;

    // Save the cart to the server
    const cartController = new CartPost();
    await cartController.save();
  }

  /**
   * Sets the cart modality.
   */
  public setModality(modality: ModalityExtend): void {
    useCartStore().modality = modality;
    this.modalityId = modality.id;

    // Reset payment type ID
    this.paymentTypeId = 0;
  }

  /**
   * Sets the payment type and prorata amounts.
   */
  public setPaymentType(paymentType: PaymentTypeDTO): void {
    this.paymentTypeId = paymentType.id;

    if (paymentType.type === KeyPaymentType.CARD || paymentType.type === KeyPaymentType.SEPA) {
      this.setProrataAmounts(100, 0);
    } else {
      this.setProrataAmounts(0, 100);
    }

    useCartStore().paymentType = paymentType;
  }

  /**
   * Marks CGV as validated.
   */
  public setCgvValidated(): void {
    this.isCgvValidated = true;
  }

  /**
   * Marks global information as validated.
   */
  public setInfoGlobalValidated(): void {
    this.isInfoGlobalValidated = true;
  }

  /**
   * Initializes the cart with default values.
   */
  public initCart(): void {
    this.modalityId = 0;
    this.paymentTypeId = 0;
    this.prorataAmountOnline = 0;
    this.prorataAmountOnlive = 0;
    this.isCgvValidated = false;
    this.isInfoGlobalValidated = false;
    this.buyerOrganizerIdWhenOrga = 0;
  }

  /**
   * Retrieves the organizer details.
   */
  public organizer(): OrganizerDTO {
    return useOrganizerStore().organizer;
  }

  /**
   * Sets prorata amounts for online and offline payments.
   */
  private setProrataAmounts(online: number, onlive: number): void {
    this.prorataAmountOnline = online;
    this.prorataAmountOnlive = onlive;
  }
}
