import { useAxios } from "@vueuse/integrations/useAxios";
import { CartDTO } from "@/dto/cart/CartDTO";
import { ClassErrorHandler } from "@/class/ClassErrorHandler";
import { CartStatus } from "@/class/cart/controller/CartStatus";

export class CartPost {
  private cart = new CartDTO();

  public async save() {
    try {
      await this.postCart();

      this.updateId();
    } catch (error) {
      const errorHandler = new ClassErrorHandler(error);

      // SECURITY
      if (errorHandler.code === "cart_already_generated") {
        // ALREADY DONE
        const cartStore = { ...useCartStore().cart };
        cartStore.status = CartStatus.generated;
        useCartStore().cart = cartStore;
      }
    }
  }

  private async postCart() {
    const urlAxios = "/cart";

    const { execute, data } = useAxios(
      urlAxios,
      { method: "POST" },
      { immediate: false }
    );
    await execute({
      data: {
        id: this.cartStore.id,
        publicId: this.cartStore.publicId,
        status: this.cartStore.status,
        userId: this.cartStore.userId,
        buyerMasterId: this.cartStore.buyerMasterId,
        orgaId: this.cartStore.orgaId,
        modalityId: this.cartStore.modalityId,
        paymentTypeId: this.cartStore.paymentTypeId,
        buyerOrganizerIdWhenOrga: this.cartStore.buyerOrganizerIdWhenOrga,
        isCgvValidated: this.cartStore.isCgvValidated,
        isInfoGlobalValidated: this.cartStore.isInfoGlobalValidated,
        prorataAmountOnlive: this.cartStore.prorataAmountOnlive,
        prorataAmountOnline: this.cartStore.prorataAmountOnline,
        archiveId: this.cartStore.archiveId,
      },
    });

    if (!data.value) {
      throw new Error("Error with cart id from backend");
    }

    this.cart = data.value;
  }

  private set cartStore(cart: CartDTO) {
    useCartStore().cart = cart;
  }

  private get cartStore(): CartDTO {
    return useCartStore().cart;
  }

  private updateId() {
    const previouscart = { ...useCartStore().cart };

    previouscart.id = this.cart.id;
    previouscart.buyerMasterId = this.cart.buyerMasterId;
    previouscart.modalityId = this.cart.modalityId;
    previouscart.paymentTypeId = this.cart.paymentTypeId;
    previouscart.isCgvValidated = this.cart.isCgvValidated;
    previouscart.isInfoGlobalValidated = this.cart.isInfoGlobalValidated;
    previouscart.isInfoGlobalValidated = this.cart.isInfoGlobalValidated;
    this.cartStore = previouscart;
  }
}
